export default {
  documentItemType: {
    verse: 1,
    chapter: 2,
    pad: 3,
    shlok: 4,
    raag: 5,
    stanza: 6,
    granth: 7,
    readingPlanDay: 8,
    readingPlanItem: 9,
    prose: 10,
    sutra: 11,
    bhasya: 12,
    vrutti: 13,
    commentary: 14,
    topic: 15,
    subTopic: 16,
    question: 17,
    answer: 18,
    preface: 19
  },
  documentType: {
    scripture: 2,
    kirtan: 1,
    readingPlan: 3,
    knowledgeBase: 4
  },
  documentTypes: {
    Kirtan: { id: 1, icon: 'mdi mdi-playlist-music-outline' },
    Scripture: { id: 2, icon: 'mdi mdi-book-open-page-variant-outline' },
    ReadingPlan: { id: 3, icon: 'mdi mdi-page-next-outline' },
    KnowledgeBase: { id: 4, icon: 'mdi mdi-firebase' }
  },
  getDocumentTypeById: function (id) {
    for (const i in this.documentTypes) {
      if (this.documentTypes[i].id === parseInt(id)) {
        return this.documentTypes[i]
      }
    }

    return null
  },
  documentItemTypes: {
    Verse: { id: 1, icon: 'mdi mdi-file-outline' },
    Chapter: { id: 2, icon: 'mdi mdi-folder' },
    Pad: { id: 3, icon: 'mdi mdi-format-align-left' },
    Shlok: { id: 4, icon: 'mdi mdi-format-align-left' },
    Raag: { id: 5, icon: '' },
    Stanza: { id: 6, icon: '' },
    Granth: { id: 7, icon: 'mdi mdi-folder' },
    ReadingPlanDay: { id: 8, icon: 'mdi mdi-calendar' },
    ReadingPlanItem: { id: 9, icon: 'mdi mdi-calendar-text' },
    Prose: { id: 10, icon: '' },
    Sutra: { id: 11, icon: 'mdi mdi-folder' },
    Bhasya: { id: 12, icon: 'mdi mdi-folder' },
    Vrutti: { id: 13, icon: 'mdi mdi-folder' },
    Commentary: { id: 14, icon: 'mdi mdi-comment-text-outline' },
    Topic: { id: 15, icon: 'mdi mdi-folder' },
    SubTopic: { id: 16, icon: 'mdi mdi-folder-outline' },
    Question: { id: 17, icon: 'mdi mdi-help-circle-outline' },
    Answer: { id: 18, icon: 'mdi mdi-crosshairs-question' },
    Preface: { id: 19, icon: 'mdi mdi-comment-text-outline' }
  },
  getDocumentItemTypeNameById: function (id) {
    for (const i in this.documentItemTypes) {
      if (this.documentItemTypes[i].id === parseInt(id)) {
        return i
      }
    }

    return null
  },
  getDocumentItemTypeById: function (id) {
    for (const i in this.documentItemTypes) {
      if (this.documentItemTypes[i].id === parseInt(id)) {
        return this.documentItemTypes[i]
      }
    }

    return null
  },
  termTypes: {
    tag: {
      id: 1,
      icon: 'mdi mdi-tag-text-outline'
    },
    category: {
      id: 2,
      icon: 'mdi mdi-layers'
    },
    location: {
      id: 3,
      icon: 'mdi mdi-crosshairs-gps'
    },
    festival: {
      id: 4,
      icon: 'mdi mdi-tent'
    },
    theme: {
      id: 5,
      icon: 'mdi mdi-layers'
    },
    raag: {
      id: 7,
      icon: 'mdi mdi-music-note-outline'
    }
  },
  getTermTypeNameById: function(id) {
    for (const i in this.termTypes) {
      if (this.termTypes[i].id === id) {
        return i
      }
    }

    return null
  }
}