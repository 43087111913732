<template>
  <v-app>
    <router-view :key="$route.fullPath"/>
    <Snackbar />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import Snackbar from '@/components/shared/Snackbar'

export default {
  components: {
    Snackbar
  },
  computed: {
    ...mapGetters('account', ['loggedIn'])
  },
  mounted() {
    if (this.loggedIn()) {
      this.$store.dispatch('languages/getLanguages')
      this.$store.dispatch('app/initialiseApplicationSettings')
      this.$store.dispatch('account/getUser')
    }
  }
}
</script>
