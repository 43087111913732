import icons from './icons'
import theme from './theme'
import analytics from './analytics'
import application from './application'
import connectionProperties from './connectionProperties'
import settings from './settings'

export default {
  // product display information
  product: {
    name: 'Lit-Admin',
    version: 'v1.0.0'
  },

  // icon libraries
  icons,

  // theme configs
  theme,

  // analytics configs
  analytics,

  // application specific configs
  application,

  connectionProperties,

  settings
}
