import DocAdmin from '@/store/data'
import configs from '@/configs'
import Vue from 'vue'

const apiConfig = configs.application.docAdmin

export default {
  namespaced: true,
  state : () => ({ 
    documentItem: undefined,
    documentItems: [],
    documentItemTranslations: [],
    parentDocumentItem: undefined,
    childDocumentItems: undefined,
    moreInfoPaneOpen: false,
    isLeafNode: undefined,
    documentItemTypes: []
  }),
  getters: {
    getDocumentItemsByParent: (state) => (parentId) => {
      if (!state.childDocumentItems) { return state.childDocumentItems }

      return state.childDocumentItems.filter((value) => {
        return Number(value.parentdocumentitemid) === Number(parentId)
      })
    },
    getDocumentItemsById: (state) => (documentItemId) => {
      if (state.documentItem && (Number(state.documentItem.documentitemid) === Number(documentItemId))) {
        return state.documentItem
      }
      
      if (state.childDocumentItems && state.childDocumentItems.length) {
        const item = state.childDocumentItems.find((v) => Number(v.documentitemid) === Number(documentItemId))
        if (item) { return item }
      }
      
      return {}
    },
    getDocumentItemTranslations: (state) => (documentItemId) => {
      return state.documentItemTranslations.filter((value) => {
        return Number(value.documentitemid) === Number(documentItemId)
      })
    },
    getDocumentItemTranslationsByLanguage: (state) => (documentItemId, languageIds) => {
      return state.documentItemTranslations.filter((value) => {
        return value.documentitemid === documentItemId && languageIds.includes(String(value.languageid))
      })
    },
    getDocumentItemTranslationsById: (state) => (documentItemTranslationId) => {
      return state.documentItemTranslations.find((v) => Number(v.id) === Number(documentItemTranslationId))
    },
    isMoreInfoPaneOpen: (state) => {
      return state.moreInfoPaneOpen
    },
    getGroupedItems: (state) => (translation) => {
      const groupedItems = []
      const groupData = state.grouping

      if (!groupData[translation.id]) { return groupedItems }

      translation.groupItems.forEach((groupItem) => {
        const documentItemTranslationIdx = state.documentItemTranslations.findIndex((x) => String(x.id) === String(groupItem.destinationid))

        if (documentItemTranslationIdx === -1) { return }
        groupedItems.push(state.documentItemTranslations[documentItemTranslationIdx])

      })

      // add itself to the array
      const selfDocumentItemTranslationIdx = state.documentItemTranslations.findIndex((x) => String(x.id) === String(translation.id))

      groupedItems.push(state.documentItemTranslations[selfDocumentItemTranslationIdx])

      return groupedItems
    },
    getGroupingById: (state) => (documentItemId) => {
      return state.grouping[documentItemId]
    }
  },
  actions: {
    getDocumentItemTypes ({ commit }, params) {

      return DocAdmin.documentItemTypes.list({ params: params }).then((response) => {
        commit('SET_DOCUMENTITEM_TYPES', response.data.results)
        // console.log(`$store.documentItems.getDocumentItemTypes: record count(${response.data.count})`)

        return Promise.resolve(response.data.results)
      }).catch((error) => { 
        console.error('$store.documentItems.getDocumentItemTypes.error',JSON.stringify(error.message.data))

        return Promise.reject(error)
      })
    },
    /**
    * Get document items
    *
    * @param {Object} context - Vuex context
    * @param {Object} params - Object with query params ex: { documentid: 1 }
    * 
    * @return {Promise<Object>}
    */
    getDocumentItems ({ commit }, params) {
      commit('SET_DOCUMENTITEMS', [])

      params.ordering = 'number'
      params.limit = 1000

      return DocAdmin.documentItems.list({ params: params }).then((response) => {
        commit('SET_DOCUMENTITEMS', response.data.results)

        return Promise.resolve(response.data.results)
      }).catch((error) => { 
        console.error('$store.documentItems.getDocumentItems.error',JSON.stringify(error.message.data))

        return Promise.reject(error)
      })
    },

    /**
    * Get document item
    *
    * @param {Object} context - Vuex context
    * @param {Object} documentItemId - Id
    * 
    * @return {Promise<Object>}
    */
    getDocumentItem ({ commit }, documentItemId) {
      commit('SET_DOCUMENTITEM', undefined)

      return DocAdmin.documentItems.get(documentItemId).then((response) => {
        commit('SET_DOCUMENTITEM', response.data)
        
        return Promise.resolve(response.data)
      }).catch((error) => { 
        console.error('$store.documentItems.getDocumentItem.error',JSON.stringify(error.message.data))

        return Promise.reject(error)
      })
    },

    getDocumentItemTranslations ({ commit }, documentItemId) {
      commit('SET_DOCUMENTITEM_TRANSLATIONS', [])

      return DocAdmin.documentItemTranslation.list({ params: { documentitemid: documentItemId } }).then((response) => {
        commit('SET_DOCUMENTITEM_TRANSLATIONS', response.data.results)        
        return Promise.resolve(response.data)
      }).catch((error) => { 
        console.error('$store.documentItems.getDocumentItemTranslations.error', JSON.stringify(error.message.data))

        return Promise.reject(error)
      }) 
    },

    getDocumentItemTranslation ({ commit }, documentItemTranslationId) {
      commit('SET_DOCUMENTITEM_TRANSLATION', [])

      return DocAdmin.documentItemTranslation.get(documentItemTranslationId).then((response) => {
        commit('SET_DOCUMENTITEM_TRANSLATION', response.data)

        return Promise.resolve(response.data)
      }).catch((error) => { 
        console.error('$store.documentItems.getDocumentItemTranslation.error', JSON.stringify(error.message.data))

        return Promise.reject(error)
      }) 
    },

    /**
    * Get grouping
    *
    * @param {Object} context - Vuex context
    * @param {Object} query - params
    * 
    * @return {Promise<Object>}
    */
    getGrouping ({ commit }, query) {

      return DocAdmin.documentItemGroupings.getInGroups.list({ params: query }).then((response) => {
        // commit('SET_GROUPING', response.data)
        // commit('SET_DOCUMENTITEM_GROUPINGS', response.data)
        return Promise.resolve(response.data)
      }).catch((error) => { 
        console.error('$store.documentItems.getGrouping.error', JSON.stringify(error.message))

        return Promise.reject(error)
      })
    },

    /**
    * Get getDocumentItemWithChildren
    *
    * @param {Object} context - Vuex context
    * @param {Number} documentItemId - Id
    * 
    * @return {Promise<Object>}
    */
    getDocumentItemWithChildren ({ state, commit, dispatch }, documentItemId) {
      commit('SET_DOCUMENTITEM', undefined)
      commit('SET_DOCUMENTITEM_TRANSLATIONS', [])
      commit('SET_CHILD_DOCUMENTITEMS', [])

      return DocAdmin.documentItemWithChildren.list({ params: { documentItemId: documentItemId } }).then((response) => {
        commit('SET_DOCUMENTITEM', response.data.documentItem)
        commit('SET_DOCUMENTITEM_TRANSLATIONS', response.data.documentItemTranslations)
        commit('SET_CHILD_DOCUMENTITEMS', response.data.childDocumentItems)

        const countChildDocumentItems = response.data.childDocumentItems.length
        const countDocumentItemTranslations = response.data.documentItemTranslations.length

        // console.log(`$store.documentItems.documentItemWithChildren: childDocumentItems=(${countChildDocumentItems}), documentItemTranslations=(${countDocumentItemTranslations}), documentItemId=(${documentItemId})`)

        /** you may have to do some logic here if leaf node then use documentitemid else parentdocumentitemid */
        let query;
        if (state.isLeafNode) {
          query = { documentItemId: documentItemId }
        } else {
          query = { parentDocumentItemId: documentItemId }
        }
        // dispatch('getGrouping', query)

        return Promise.resolve(response.data)
      }).catch((error) => { 
        console.error('$store.documentItems.documentItemWithChildren.error', JSON.stringify(error.message.data))

        return Promise.reject(error)
      })
    },
    updateDocumentItem ({ commit }, { id, payload } ) {
      payload.documentitemid = id
      const logData = {
        source: '$store.updateDocumentItem',
        silent: false,
        text: 'something',
        error: false
      }

      return DocAdmin.documentItems.patch(id, payload).then((response) => {
        commit('UPDATE_DOCUMENTITEM', { data: response.data, documentitemid: id })
        logData.text = `Successfully updated documentItem (${id}).`

        return Promise.resolve(response.data)
      }).catch((error) => {
        logData.text = `${JSON.stringify(error.response)}`
        logData.error = true

        return Promise.reject(error.response.data)
      })
    },

    createDocumentItem  ({ commit }, payload) {
      const logData = {
        source: '$store.createDocumentItem',
        silent: false,
        text: 'something',
        error: false
      }

      return DocAdmin.documentItems.create(payload).then((response) => {
        commit('SET_DOCUMENTITEM', response.data)
        logData.text = `Successfully created documentItem (${response.data.documentitemid}).`

        return Promise.resolve(response.data)
      }).catch((error) => {
        logData.text = `${error.response}`
        logData.error = true

        return Promise.reject(error.response.data)
      })

    },

    createDocumentItemTranslation  ({ commit }, { id, payload } ) {
      const logData = {
        source: '$store.createDocumentItemTranslation',
        silent: false,
        text: 'something',
        error: false
      }

      return DocAdmin.documentItemTranslation.create(payload).then((response) => {
        commit('SET_DOCUMENTITEM_TRANSLATION', response.data)
        logData.text = `Successfully updated documentItemTranslation (${id}).`

        return Promise.resolve(response.data)
      }).catch((error) => {
        logData.text = `${error.response}`
        logData.error = true

        return Promise.reject(error.response.data)
      })

    },

    updateDocumentItemTranslation ({ commit }, { id, payload } ) {
      payload.id = id
      const logData = {
        source: '$store.updateDocumentItemTranslation',
        silent: false,
        text: 'something',
        error: false
      }

      return DocAdmin.documentItemTranslation.patch(id, payload).then((response) => {
        commit('UPDATE_DOCUMENTITEM_TRANSLATION', { data: response.data, translationId: id })
        logData.text = `Successfully updated documentItemTranslation (${id}).`

        return Promise.resolve(response.data)
      }).catch((error) => {
        logData.text = `${error.response}`
        logData.error = true

        return Promise.reject(error.response.data)
      })
    },

    deleteDocumentItemTranslation ({ commit }, translationId) {
      return DocAdmin.documentItemTranslation.remove(translationId).then((response) => {
        commit('DELETE_DOCUMENTITEM_TRANSLATION', translationId)

        return Promise.resolve(response)
      }).catch((error) => {
        console.error('$store.deleteDocumentTranslation.error', JSON.stringify(error.response.data))

        return Promise.reject(error.response.data)
      })
    },

    deleteDocumentItemsWithChildren ({ commit }, documentItemId) {
      const params = {
        params: {
          documentItemId: documentItemId
        }
      }
      
      DocAdmin.deleteDocumentItemsWithChildren.removeWithParams(params).then((response) => {
        commit('DELETE_DOCUMENTITEM_W_CHILDREN', documentItemId)
      }).catch((error) => {
        logData.text = `${error}`
        logData.error = true
      })
    }
  },
  mutations: {
    SET_DOCUMENTITEM_TYPES (state, documentItemTypes) {
      state.documentItemTypes = documentItemTypes
    },
    SET_DOCUMENTITEM (state, documentItem) {
      if (!documentItem) { return }
      state.documentItem = documentItem
      try {
        state.isLeafNode = JSON.parse(state.documentItem.breadcrumb).at(-1).isleafnode
      } catch (err) {
        state.isLeafNode = false
        /* we should try and get it from DS if we dont understand it.
         * isLeafNode()  {
         *   if (!this.documentItem) {
         *     return false
         *   }
         *   const docItemStructure = this.getDocumentItemDataStructureById(this.documentItem.documentitemtypeid)
         *
         *   return docItemStructure && docItemStructure.leafNode
         * }
         * 
         * getter('documents/getDocumentItemDataStructureById', state.documentItem.documentitemtypeid)
         */
        console.error(`Failed to determine leafnode, based on follow breadcrumb=${documentItem.breadcrumb}`)
      }
    },
    SET_DOCUMENTITEMS (state, documentItems) {
      state.documentItems = documentItems
    },
    SET_CHILD_DOCUMENTITEMS (state, data) {
      state.childDocumentItems = data
    },
    SET_DOCUMENTITEM_TRANSLATIONS (state, documentItemTranslations) {
      state.documentItemTranslations = documentItemTranslations
    },
    SET_DOCUMENTITEM_TRANSLATION (state, documentItemTranslation) {
      state.documentItemTranslations.push(documentItemTranslation)
    },
    SET_DOCUMENT_NAVIGATION_ITEMS (state, items) {
      state.documentNavigationItems = items
    },
    SET_PARENTDOCUMENT_ITEM (state, parentDocumentItem) {
      state.parentDocumentItem = parentDocumentItem

      if (parentDocumentItem && !parentDocumentItem.parentdocumentitemid) {
        state.isLeafNode = JSON.parse(parentDocumentItem.breadcrumb).at(-1).isleafnode
      }
    },
    SET_GROUPING (state, data) {
      // {"105281":[{"source_documentitemid":105281,"destination_documentitemid":105280,"languageid":4,"render":"separateParagraphs","number":4}]}

      if (!state.documentItemTranslations.length) { return }
      if (!Object.keys(data).length) { return }

      state.grouping = data

      for (const [stemItemId, groupItems] of Object.entries(state.grouping)) {
        groupItems.forEach((item) => {
          const ditIndex = state.documentItemTranslations.findIndex((x) => { 
            return x.documentitemid === item.destination_documentitemid && x.languageid === item.languageid
          })

          if (ditIndex === -1) { return }
          Vue.set(state.documentItemTranslations[ditIndex], 'grouped', true)
          Vue.set(state.documentItemTranslations[ditIndex], 'documentitemgroupings', item)
        })
      }

      Object.keys(state.grouping).forEach((sourceDocumentItemId) => {
        const i = state.grouping[sourceDocumentItemId].shift()
        const sourceIndex = state.documentItemTranslations.findIndex((dit) => {
          return parseInt(dit.documentitemid) === parseInt(sourceDocumentItemId) && dit.languageid === i.languageid
        })
        
        if (sourceIndex === -1) { return }
        Vue.set(state.documentItemTranslations[sourceIndex], 'grouped', true)
        Vue.set(state.documentItemTranslations[sourceIndex], 'documentitemgroupings', i)
      })

    },
    UPDATE_DOCUMENTITEM_TRANSLATION (state, { data, translationId }) {
      const index = state.documentItemTranslations.findIndex((item) => item.id === translationId)

      if (index !== -1) state.documentItemTranslations.splice(index, 1, data)
    },
    PATCH_DOCUMENTITEM (state, { field, value }) {
      state.documentItem[field] = value
    },
    UPDATE_DOCUMENTITEM (state, { data }) {
      state.documentItem = data
    },
    /**
     * We update the documentItemTranslation by field only, so its not a full object update
     * 
     * @param {*} state 
     * @param {String} field 
     * @param {String} value 
     * @param {String} id 
     */
    PATCH_DOCUMENTITEM_TRANSLATION (state, { field, value, id }) {
      const index = state.documentItemTranslations.findIndex((item) => item.id === id)

      if (index !== -1) {
        state.documentItemTranslations[index][field] = value
  
        return
      }
      
      // otherwise create one
      
    },
    DELETE_DOCUMENTITEM_TRANSLATION (state, translationId) {
      const index = state.documentItemTranslations.findIndex((documentItemTranslation) => documentItemTranslation.id === translationId)
      
      state.documentItemTranslations.splice(index, 1)
    },
    DELETE_DOCUMENTITEM_W_CHILDREN (state, documentItemId) {
      state.documentItemTranslations = state.documentItemTranslations.filter((documentItemTranslation) => {
        return documentItemTranslation.documentitemid !== documentItemId
      })

      state.childDocumentItems = state.childDocumentItems.filter((documentItem) => {
        return documentItem.documentitemid !== documentItemId
      })
    },
    toggleMoreInfo (state) {
      state.moreInfoPaneOpen = !state.moreInfoPaneOpen
    }
  }
}