import configs from '@/configs'
import { axiosBase } from '@/helpers/axiosIntercept'

const docAdminConfig = configs.application.docAdmin

const GenericApi = (resource) => {
  return {
    // Get a list of resources
    list: (config) => axiosBase.get(`${resource}`, config),
    // Get a single resource by its id
    get: (id, config) => axiosBase.get(`${resource}${id}/`, config),
    // Create a new resource
    create: (payload) => axiosBase.post(`${resource}`, payload),
    // Replace an existing resource with payload
    update: (id, payload) => axiosBase.put(`${resource}${id}/`, payload),
    // Merge new payload into a resource
    patch: (id, payload) => axiosBase.patch(`${resource}${id}/`, payload),
    // Remove a resource by its id
    remove: (id) => axiosBase.delete(`${resource}${id}/`),
    // Remove with params
    removeWithParams: (params) => axiosBase.delete(`${resource}`, params)
  }
}

export default {
  adminSettingsCouchbase: {
    ...GenericApi(docAdminConfig.endpointUtils.adminSettingsCouchbase)
  }
}