<template>
  <v-snackbar v-model="$store.state.app.snackbar.show">
    {{ $store.state.app.snackbar.text }}
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="$store.commit('app/hideSnackBar')" >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
}
</script>