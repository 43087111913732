import configs from '@/configs'
import axios from 'axios'
import mconfig from '@/configs/metaConfig'

const GenericApi = (resource) => {
  return {
    // Get a list of resources
    list: (config) => axios.get(`${resource}`, config),
    // Get a single resource by its id
    get: (id, config) => axios.get(`${resource}${id}/`, config),
    // Create a new resource
    create: (payload) => axios.post(`${resource}`, payload),
    // Replace an existing resource with payload
    update: (id, payload) => axios.put(`${resource}${id}/`, payload),
    // Merge new payload into a resource
    patch: (id, payload) => axios.patch(`${resource}${id}/`, payload),
    // Remove a resource by its id
    remove: (id) => axios.delete(`${resource}${id}/`),
    // Remove with params
    removeWithParams: (params) => axios.delete(`${resource}`, params)
  }
}

export default {
  bhaktisoundSearch: {
    ...GenericApi(`${mconfig.audio.bhaktisoundBaseUrl}/secure/search`)
  }
}