import Vue from 'vue'
import Vuex from 'vuex'

// Global vuex
import app from './app'
import languages from './languages'
import documents from './documents'
import documentItems from './documentItems'
import account from './account'
import terms from './terms'
import search from './search'

Vue.use(Vuex)

/**
 * Main Vuex Store
 */
const store = new Vuex.Store({
  modules: {
    app,
    languages,
    documents,
    documentItems,
    account,
    terms,
    search
  }
})

export default store