import Vue from 'vue'
import App from '@/App.vue'

// VUEX - https://vuex.vuejs.org/
import store from '@/store'

// VUE-ROUTER - https://router.vuejs.org/
import router from '@/router'

// PLUGINS
import vuetify from './plugins/vuetify'
import './plugins/vue-head'
// import logger from './plugins/logger'

// FILTERS
import './filters/capitalize'
import './filters/lowercase'
import './filters/uppercase'
import './filters/placeholder'
import './filters/trim'
import './filters/formatDate'

// STYLES
// Main Theme SCSS
import './assets/scss/theme.scss'
import './assets/scss/custom.scss'

import _ from 'lodash'
Object.defineProperty(Vue.prototype, '$_', { value: _ })

// // Install rules and localization
// Object.keys(rules).forEach(rule => {
//   extend(rule, rules[rule]);
// });

// // Install components globally
// Vue.component('ValidationObserver', ValidationObserver);
// Vue.component('ValidationProvider', ValidationProvider);

// Set this to false to prevent the production tip on Vue startup.
Vue.config.productionTip = false

/*
|---------------------------------------------------------------------
| Main Vue Instance
|---------------------------------------------------------------------
|
| Render the vue application on the <div id='app'></div> in index.html
|
| https://vuejs.org/v2/guide/instance.html
|
*/
Vue.filter('capitalize', (value) => {
  if (!value) return ''
  value = value.toString()

  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('stripHtml', (value) => {
  return value.replace(/<\/?[^>]+>/ig, ' ')
})

Vue.filter('uppercase', (value) => {
  return value.toUpperCase() || ''
})

Vue.filter('truncate', (text, stop, clamp) => {
  return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
})

Vue.filter('titlecase', (value) => {
  if (!value) {return value }

  return value.toLowerCase().replace(/(?:^|\s|-)\S/g, (x) => x.toUpperCase())
})

export default new Vue({
  vuetify,
  router,
  store,
  // logger,
  render: (h) => h(App)
}).$mount('#app')