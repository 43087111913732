import configs from '@/configs'
import axios from 'axios'
import { axiosBase } from '@/helpers/axiosIntercept'

const docAdminConfig = configs.application.docAdmin

const GenericApi = (resource) => {
  return {
    // Get a list of resources
    list: (config) => axiosBase.get(`${resource}`, config),
    // Get a single resource by its id
    get: (id, config) => axiosBase.get(`${resource}${id}/`, config),
    // Create a new resource
    create: (payload) => axiosBase.post(`${resource}`, payload),
    // Replace an existing resource with payload
    update: (id, payload) => axiosBase.put(`${resource}${id}/`, payload),
    // Merge new payload into a resource
    patch: (id, payload) => axiosBase.patch(`${resource}${id}/`, payload),
    // Remove a resource by its id
    remove: (id) => axiosBase.delete(`${resource}${id}/`),
    // Remove with params
    removeWithParams: (params) => axiosBase.delete(`${resource}`, params)
  }
}

export default {
  documents: {
    ...GenericApi(docAdminConfig.endpoints.documents),
    types: { ...GenericApi(docAdminConfig.endpoints.documentTypes) }
    // custom: axios.get(`${base}/some/custom/route`, config)
  },
  documentAuthors: {
    ...GenericApi(docAdminConfig.endpoints.documentAuthors)
  },
  documentTranslations: {
    ...GenericApi(docAdminConfig.endpoints.documentTranslations)
  },
  documentItems: {
    ...GenericApi(docAdminConfig.endpoints.documentItems)
  },
  documentItemNextPrevious: {
    ...GenericApi(docAdminConfig.endpoints.documentItemNextPrevious)
  },
  documentItemWithChildren: {
    ...GenericApi(docAdminConfig.endpoints.documentItemWithChildren)
  },
  deleteDocumentItemsWithChildren: {
    ...GenericApi(docAdminConfig.endpoints.deleteDocumentItemsWithChildren)
  },
  documentItemGroupings: {
    ...GenericApi(docAdminConfig.endpoints.documentItemGroupings),
    getInGroups: {
      ...GenericApi(docAdminConfig.endpoints.documentItemGroupingsByGroup)
      // return axiosBase.get(docAdminConfig.endpoints.documentItemGroupingsByGroup, params)
    }
  },
  documentItemTypes: {
    ...GenericApi(docAdminConfig.endpoints.documentItemTypes)
  },
  documentItemTranslation: {
    ...GenericApi(docAdminConfig.endpoints.documentItemTranslations)
  },
  definitions: {
    ...GenericApi(docAdminConfig.endpoints.definitions),
    explanations: {
      ...GenericApi(docAdminConfig.endpoints.definitionExplanations)
    },
    synonyms: {
      ...GenericApi(docAdminConfig.endpoints.synonyms)
    },
    reassign: {
      ...GenericApi(docAdminConfig.endpoints.definitionsReassign)
    },
    explanationTranslations: {
      ...GenericApi(docAdminConfig.endpoints.definitionExplanationTranslations),
      deleteByParams: function (params) {
        return axiosBase.delete(docAdminConfig.endpoints.definitionExplanationTranslations, params)
      }
    }
  },
  persons: {
    ...GenericApi(docAdminConfig.endpoints.persons)
  },
  personTranslations: {
    ...GenericApi(docAdminConfig.endpoints.personTranslations)
  },
  phraseTranslations: {
    ...GenericApi(docAdminConfig.endpoints.phraseTranslations)
  },
  languages: {
    ...GenericApi(docAdminConfig.endpoints.languages)
  },
  metadata: {
    ...GenericApi(docAdminConfig.endpoints.metadata)
  },
  terms: {
    ...GenericApi(docAdminConfig.endpoints.terms)
  },
  termConnections: {
    ...GenericApi(docAdminConfig.endpoints.termConnections)
  },
  termRelationships: {
    ...GenericApi(docAdminConfig.endpoints.termRelationship)
  },
  termTranslations: {
    ...GenericApi(docAdminConfig.endpoints.termTranslations)
  },
  termTypes: {
    ...GenericApi(docAdminConfig.endpoints.termTypes)
  },
  connections: {
    ...GenericApi(docAdminConfig.endpoints.connections),
    // http://localhost:9090/api/connections/delete?sourceid=855&destinationid=3548&source_connectiontypeid=6
    deleteByParams: function(urlparams) {
      return axiosBase.delete(`${docAdminConfig.endpoints.connections}delete`, urlparams)
    }
  },
  connectionsInOut: {
    ...GenericApi(docAdminConfig.endpoints.connectionsInOut)
  },
  dataStructures: {
    ...GenericApi(docAdminConfig.endpoints.dataStructures)
  },
  documentSearch: {
    ...GenericApi(docAdminConfig.endpoints.documentSearch)
  },
  imageManager: {
    upload: function (formData) {
      return axiosBase.post(docAdminConfig.endpoints.imageManagerUpload, formData)
    },
    delete: function (filePathData) {
      return axiosBase.post(docAdminConfig.endpoints.imageManagerDelete, filePathData)
    }
  },
  account: {
    register: {
      ...GenericApi(docAdminConfig.endpoints.account.register)
    },
    token: {
      ...GenericApi(docAdminConfig.endpoints.account.token)
    },
    tokenRefresh: {
      ...GenericApi(docAdminConfig.endpoints.account.tokenRefresh)
    },
    tokenVerify: {
      ...GenericApi(docAdminConfig.endpoints.account.tokenVerify)
    },
    logout: {
      ...GenericApi(docAdminConfig.endpoints.account.logout)
    },
    profile: {
      ...GenericApi(docAdminConfig.endpoints.account.profile)
    }
  },
  sync: {
    adminSettingsCouchbase: {
      ...GenericApi(docAdminConfig.endpointUtils.adminSettingsCouchbase)
    },
    export: {
      readingPlan: function (formData) {
        return axiosBase.post(docAdminConfig.endpointUtils.export.readingPlan, formData)
      },
      readingPlans: function (formData) {
        return axiosBase.post(docAdminConfig.endpointUtils.export.readingPlans, formData)
      }
    }
  }
}