// this.$log.error('store.getLanguages', JSON.stringify(error.message.data)
import DocAdmin from '@/store/data'
import configs from '@/configs'
import types from '@/helpers/types'
import Vue from 'vue'
import app from '@/store/app'

const apiConfig = configs.application.docAdmin

export default {
  namespaced: true,
  state : () => ({ 
    document: undefined,
    documents: undefined,
    documentTranslations: undefined,
    documentAuthors: undefined,
    dataStructure: {},
    dataStructures: [],
    documentTypes: undefined,
    selectedLanguages: {},
    supportedLanguages: []
  }),
  getters: {
    getDocumentDataStructure: (state) => () => {
      if (!state.document) { return undefined }

      return state.dataStructure[state.document.documenttypeid.name]
    },
    getDocumentItemDataStructure: (state) => (documentItemTypeName) => {
      if (!state.document) { return undefined }

      return state.dataStructure[state.document.documenttypeid.name].documentItems[documentItemTypeName]
    },
    getDocumentItemDataStructureById: (state) => (documentItemTypeId) => {
      if (!state.document) { return undefined }
      const docItemTypeName = types.getDocumentItemTypeNameById(documentItemTypeId)

      return state.dataStructure[state.document.documenttypeid.name].documentItems[docItemTypeName]
    },
    getAllowedChildrenById: (state, getters) => (documentItemTypeId) =>  {
      const docItemStructure = getters.getDocumentItemDataStructureById(documentItemTypeId)

      if (!docItemStructure) {
        return []
      }

      return (docItemStructure.allowedChildren ? docItemStructure.allowedChildren : [])
    },
    renderComponent: (state, getters) => (documentItemTypeId, componentName) => {
      const structure = getters.getDocumentItemDataStructureById(documentItemTypeId)
      
      if (!structure) { return false }
      if (structure[componentName]) { return structure[componentName] }

      return false
    },

    renderTranslationField: (state, getters) => (documentItemTypeId, fieldName) => {
      const docItemTypeName = types.getDocumentItemTypeNameById(documentItemTypeId)

      if (!docItemTypeName) { return false }

      const structure = getters.getDocumentItemDataStructure(docItemTypeName)

      if (!structure) { return false }
      if (structure.translationFields && structure.translationFields[fieldName]) {
        return true
      }

      return false
    },

    renderField: (state, getters) => (documentItemTypeId, fieldName) => {

      const docItemTypeName = types.getDocumentItemTypeNameById(documentItemTypeId)
      const structure = getters.getDocumentItemDataStructure(docItemTypeName)

      if (!structure) {
        return false
      }
      
      if (structure.fields && structure.fields[fieldName]) {
        return true
      }

      return false
    },

    isImageEnabled: (state, getters) => () => {
      const docStructure = getters.getDocumentDataStructure()
      
      if (!docStructure) { return false }

      return docStructure.enableItemImage
    },
    isEnableTranslationImage: (state, getters) => () => {
      const docStructure = getters.getDocumentDataStructure()

      if (!docStructure) { return false }

      return docStructure.enableTranslationImage
    },
    allowedTermTypes: (state, getters) => () => {
      const docStructure = getters.getDocumentDataStructure()

      if (!docStructure) { return [] }

      return docStructure.allowedTermTypes
    },
    selectedLanguagesByDocument: (state) => () => {
      if (Object.keys(state.selectedLanguages).length === 0) {
        return []
      }

      return state.selectedLanguages[state.document.documentid]
    },
    itemFieldRules: (state, getters) => (documentItemTypeId, fieldName, rule) => {
      const docItemTypeName = types.getDocumentItemTypeNameById(documentItemTypeId)
      const structure = getters.getDocumentItemDataStructure(docItemTypeName)

      try {
        return structure.fields[fieldName].required ? rule : ''
      } catch (err) {
        console.warn(`itemFieldRules, could not find fields.${fieldName}.required in ${JSON.stringify(structure.fields)}.`)
      }

      return ''
    },
    itemTranslationFieldRules: (state, getters) => (documentItemTypeId, fieldName, rule) => {
      const docItemTypeName = types.getDocumentItemTypeNameById(documentItemTypeId)
      const structure = getters.getDocumentItemDataStructure(docItemTypeName)

      try {
        return structure.translationFields[fieldName].required ? rule : ''
      } catch (err) {
        console.warn(`itemTranslationFieldRules, could not find fields.translationFields.${fieldName}.required in ${JSON.stringify(structure.translationFields)}.`)
      }

      return ''
    }
  },
  actions: {
    /**
    * Get document types
    *
    * @param {Object} context - Vuex context
    * 
    * @return {Promise<Object>}
    */
    getDocumentTypes ({ commit }) {
      return DocAdmin.documents.types.list().then((response) => {
        commit('SET_DOCUMENT_TYPES', response.data.results)
      }).catch((error) => console.error('store.getDocumentTypes', JSON.stringify(error.message.data)))
    },

    /**
    * Get Single document
    *
    * @param {Object} context - Vuex context
    * @param {Number} documentId
    * @param {Boolean} loadTranslations
    * 
    * @return {Promise<Object>}
    */
    getDocument ({ commit }, { documentId }) {
      commit('SET_DOCUMENT', null)
      
      return DocAdmin.documents.get(documentId).then((response) => {
        commit('SET_DOCUMENT', response.data)
        if (response.data.datastructureid) {
          commit('SET_DATA_STRUCTURE', { 
            data: JSON.parse(response.data.datastructureid.json), 
            documentType: response.data.documenttypeid.name
          })
        }

        commit('app/updateMenu', {
          text: response.data.englishtitle,
          key: 'document',
          items: [
            { icon: 'mdi-home', key: 'document.home', text: 'Home', link: `/app/document/${response.data.documentid}/home` },
            { icon: 'mdi-translate', key: 'document.translations', text: 'Translations', link: `/app/document/${response.data.documentid}/translations` },
            { icon: 'mdi-cog-outline', key: 'document.settings', text: 'Settings', link: `/app/document/${response.data.documentid}/settings` }
          ]
        }, { root: true })

        return Promise.resolve(response.data)
      }).catch((error) => {
        console.error('$store.document.getDocument:', JSON.stringify(error.message.data))

        return Promise.reject(error)
      })
    },

    /**
    * Create Single document
    *
    * @param {Object} context - Vuex context
    * @param {Object} payload
    * 
    * @return {Promise<Object>}
    */
    createDocument ({ commit }, payload) {
      commit('SET_DOCUMENT', null)
      
      return DocAdmin.documents.create(payload).then((response) => {
        commit('SET_DOCUMENT', response.data)

        if (response.data.datastructureid) {
          commit('SET_DATA_STRUCTURE', { 
            data: JSON.parse(response.data.datastructureid.json), 
            documentType: response.data.documenttypeid.name
          })
        }

        commit('app/updateMenu', {
          text: response.data.englishtitle,
          key: 'document',
          items: [
            { icon: 'mdi-home', key: 'document.home', text: 'Home', link: `/app/document/${response.data.documentid}/home` },
            { icon: 'mdi-translate', key: 'document.translations', text: 'Translations', link: `/app/document/${response.data.documentid}/translations` },
            { icon: 'mdi-cog-outline', key: 'document.settings', text: 'Settings', link: `/app/document/${response.data.documentid}/settings` }
          ]
        }, { root: true })

        return Promise.resolve(response.data)
      }).catch((error) => {
        console.error('$store.document.createDocument:', JSON.stringify(error.message.data))

        return Promise.reject(error)
      })
    },

    /**
    * Update Document
    *
    * @param {Object} context - Vuex context
    * @param {Number} id
    * 
    * @return {Promise<Object>}
    */
    updateDocument ({ commit }, payload ) {

      return DocAdmin.documents.patch(payload.documentid, payload).then((response) => {
        commit('UPDATE_DOCUMENT', payload.id, response.data)
        
        return Promise.resolve(response.data)
      }).catch((error) => {
        return Promise.reject(error)
      })
    },

    /**
    * Create Document Authors
    *
    * @param {Object} context - Vuex context
    * @param {Number} id
    * 
    * @return {Promise<Object>}
    */
    createDocumentAuthors ({ commit }, payload ) {
      return DocAdmin.documentAuthors.create(payload).then((response) => {
        commit('UPDATE_DOCUMENT_AUTHORS', response.data)

        return Promise.resolve(response.data)
      }).catch((error) => {
        return Promise.reject(error)
      })
    },

    /**
    * Update Document Authors
    *
    * @param {Object} context - Vuex context
    * @param {Number} id
    * 
    * @return {Promise<Object>}
    */
    deleteDocumentAuthors ({ commit }, id ) {
      return DocAdmin.documentAuthors.remove(id).then((response) => {
        commit('DELETE_DOCUMENT_AUTHOR', id)

        return Promise.resolve(response.data)
      }).catch((error) => {
        return Promise.reject(error)
      })
    },

    /**
    * Get Document Translations
    *
    * @param {Object} context - Vuex context
    * @param {Number} documentId
    * 
    * @return {Promise<Object>}
    */
    getDocumentTranslations ({ commit }, documentId) {
      commit('SET_DOCUMENT_TRANSLATIONS', [])
      commit('SET_INITIAL_READING_LANGUAGE', [])

      const params = { documentid_id: documentId }

      return DocAdmin.documentTranslations.list({ params: params }).then((response) => {
        commit('SET_DOCUMENT_TRANSLATIONS', response.data.results)
        commit('SET_INITIAL_READING_LANGUAGE', response.data.results)

        return Promise.resolve(response.data)
      }).catch((error) => {
        return Promise.reject(error)
      })
    },

    /**
    * Create Document Translation
    *
    * @param {Object} context - Vuex context
    * @param {Object} payload
    * 
    * @return {Promise<Object>}
    */
    createDocumentTranslation ({ commit }, payload) {
      return DocAdmin.documentTranslations.create(payload).then((response) => {
        commit('SET_DOCUMENT_TRANSLATION', response.data)

        return Promise.resolve(response.data)
      }).catch((error) => {
        return Promise.reject(error)
      })
    },

    /**
    * Update Document Translation
    *
    * @param {Object} context - Vuex context
    * @param {Object} payload
    * 
    * @return {Promise<Object>}
    */
    updateDocumentTranslation ({ commit }, payload ) {

      return DocAdmin.documentTranslations.patch(payload.id, payload).then((response) => {
        commit('UPDATE_DOCUMENT_TRANSLATION', payload.id, response.data)

        return Promise.resolve(response.data)
      }).catch((error) => {
        return Promise.reject(error)
      })
    },

    /**
    * delete Document Translation
    *
    * @param {Object} context - Vuex context
    * @param {Number} documentTranslationId
    * 
    * @return {Promise<Object>}
    */
    deleteDocumentTranslation ({ commit }, documentTranslationId) {
      return DocAdmin.documentTranslations.remove(documentTranslationId).then((response) => {
        commit('DELETE_DOCUMENT_TRANSLATION', documentTranslationId)

        return Promise.resolve(response.data)
      }).catch((error) => {
        return Promise.reject(error)
      })
    },
    
    /**
    * Get Document Authors
    *
    * @param {Object} context - Vuex context
    * @param {Number} documentId
    * 
    * @return {Promise<Object>}
    */
    getDocumentAuthors ({ commit }, documentId) {
      commit('SET_DOCUMENT_AUTHORS', [])

      const params = { documentid: documentId }

      return DocAdmin.documentAuthors.list({ params: params }).then((response) => {
        commit('SET_DOCUMENT_AUTHORS', response.data.results)

        return Promise.resolve(response.data)
      }).catch((error) => {
        return Promise.reject(error)
      })
    },

    /**
    * Load documents by type
    *
    * @param {Object} context - Vuex context
    * @param {Number} documentType - Document type
    * @param {Object} pageSize - Page size number of records
    * 
    * @return {Promise<Object>}
    */
    getDocuments ({ commit }, params) {
      return DocAdmin.documents.list({ params: params }).then((response) => {
        commit('SET_DOCUMENTS', response.data)

        return Promise.resolve(response.data)
      }).catch((error) => {
        console.error('store.getDocuments.error', error.response)

        return Promise.reject(error)
      })
    },

    /**
    * Load default datastructure
    *
    * @param {Object} context - Vuex context
    * 
    * @return {Promise<Object>}
    */
    getDefaultDataStructure ({ commit }) {
      return DocAdmin.dataStructures.list({ params: { isdefault:true } }).then((response) => {
        commit('SET_DEFAULT_DATA_STRUCTURE', JSON.parse(response.data.results[0].json))

        return Promise.resolve(JSON.parse(response.data.results[0].json))
      }).catch((error) => {
        return Promise.reject(error)
      })
    },

    /**
    * Load datastructures
    *
    * @param {Object} context - Vuex context
    * 
    * @return {Promise<Object>}
    */
    getDataStructures ({ commit }) {
      return DocAdmin.dataStructures.list().then((response) => {
        commit('SET_DATA_STRUCTURES', response.data.results)
      }).catch((error) => console.error('store.getDataStructures', JSON.stringify(error.message.data)))
    }
  },
  mutations: {
    SET_DEFAULT_DATA_STRUCTURE (state, data) {
      state.dataStructure = data
    },
    SET_DATA_STRUCTURE (state, { data, documentType }) {
      if (data && documentType) {
        state.dataStructure[documentType] = data
      }
    },
    SET_DATA_STRUCTURES (state, structures) {
      state.dataStructures = structures
    },
    SET_DOCUMENT (state, document) {
      state.document = document
    },
    UPDATE_DOCUMENT (state, documentData) {
      state.document = documentData
    },
    UPDATE_DOCUMENT_AUTHORS (state, author) {
      state.documentAuthors.push(author)
    },
    DELETE_DOCUMENT_AUTHOR (state, documentAuthorId) {
      const index = state.documentAuthors.findIndex((documentAuthor) => documentAuthor.id === documentAuthorId)

      state.documentAuthors.splice(index, 1)
    },
    SET_DOCUMENT_TYPES (state, documentTypes) {
      state.documentTypes = documentTypes
    },
    SET_DOCUMENTS (state, documents) {
      state.documents = documents
    },
    SET_DOCUMENT_TRANSLATION (state, documentTranslation) {
      state.documentTranslations.push(documentTranslation)
    },
    SET_DOCUMENT_TRANSLATIONS (state, documentTranslations) {
      state.documentTranslations = documentTranslations
    },
    SET_DOCUMENT_AUTHORS (state, documentAuthors) {
      state.documentAuthors = documentAuthors
    },
    CREATE_DOCUMENT_TRANSLATION (state, data) {
      state.documentTranslations.push(data)
    },
    UPDATE_DOCUMENT_TRANSLATION (state, { data, translationId }) {
      const index = state.documentTranslations.findIndex((item) => Number(item.id) === Number(translationId))

      // if found
      if (index !== -1) { 
        state.documentTranslations.splice(index, 1, data) 

        return
      }
    },
    DELETE_DOCUMENT_TRANSLATION(state, translationId) {
      const index = state.documentTranslations.findIndex((documentTranslation) => documentTranslation.id === Number(translationId))

      state.documentTranslations.splice(index, 1)
    },
    SET_DOCUMENTITEM (state, documentItem) {
      state.documentItem = documentItem
    },
    SET_INITIAL_READING_LANGUAGE: (state, translations) => {

      if (translations.length === 0) { return [] }

      if (translations.length === 1) { 
        state.supportedLanguages = [translations[0].languageid.languageid]
        
        Vue.set(state.selectedLanguages, state.document.documentid, [String(translations[0].languageid.languageid)])
        localStorage.setItem('selectedLanguages', JSON.stringify(state.selectedLanguages))

        return []
      }

      state.supportedLanguages = translations.map((i) => { 
        return i.languageid.languageid
      })

      // check if localstorage has data
      const selectedLanguagesLs = JSON.parse(localStorage.getItem('selectedLanguages'))
      
      if (!selectedLanguagesLs) { return true }

      if (selectedLanguagesLs[state.document.documentid] && selectedLanguagesLs[state.document.documentid].length) {
        Vue.set(state.selectedLanguages, state.document.documentid, selectedLanguagesLs[state.document.documentid])

        return true
      }

      // if language is already set for documentid
      if (state.selectedLanguages[state.document.documentid] && state.selectedLanguages[state.document.documentid].length) {
        return true
      }

      if (state.supportedLanguages.length !== 0) {
        Vue.set(state.selectedLanguages, state.document.documentid, [String(state.supportedLanguages[0])])
      }

      // update localStorage
      localStorage.setItem('selectedLanguages', JSON.stringify(state.selectedLanguages))

      return true
    },
    SET_READING_LANGUAGE: (state, { documentId, languageIds }) => {
      Vue.set(state.selectedLanguages, documentId, languageIds)
      localStorage.setItem('selectedLanguages', JSON.stringify(state.selectedLanguages))
    }
  }
}