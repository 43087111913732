import configs from '@/configs'
import Vuetify from '../../plugins/vuetify'

const { product, theme } = configs
const { isRTL, globalTheme } = theme

export default {
  namespaced: true,
  state : () => ({ 
    product,
    logItems: [],
    debug: false,
    editing: false,
    snackbar: {
      show: false,
      text: undefined
    },
    menu: [{
      items: [{ 
        icon: 'mdi-view-dashboard-outline', 
        key: 'menu.dashboard', 
        text: 'Dashboard', 
        link: '/app/dashboard'
      },{ 
        icon: 'mdi-folder-multiple-outline', 
        key: 'menu.documents', 
        text: 'Documents', 
        link: '/app/documents'
      },{ 
        icon: 'mdi-book-alphabet', 
        key: 'menu.definitions', 
        text: 'Definitions', 
        link: '/app/definitions'
      },{ 
        icon: 'mdi-account-supervisor-circle', 
        key: 'menu.persons', 
        text: 'Persons', 
        link: '/app/persons'
      },{ 
        icon: 'mdi-at', 
        key: 'menu.terms', 
        text: 'Terms', 
        link: '/app/terms'
      }]
    }],
    helperEnable: false,
    helperTransliteration: 'latin',
    helperLayout: 'side',
    showRelationships: true,
    readingLayout: 'default',
    readingLanguageOrder: [ "4", "2", "1" ], // sort by sa gu, en
    drawer: true,
    // app theme dark or light
    globalTheme,
    // themes and layout configurations
    isRTL
  }),
  getters: { },
  actions: {
    initialiseApplicationSettings({ commit }) {
      const debug = localStorage.getItem('appDebug')
      const theme = localStorage.getItem('appTheme')
      const appEditing = localStorage.getItem('appEditing')
      const helperEnabled = localStorage.getItem('appHelperEnabled')
      const readingLayout = localStorage.getItem('appReadingLayout')
      const readingLanguageOrder = localStorage.getItem('appReadingLanguageOrder')

      localStorage.setItem('appApiUri', process.env.VUE_APP_DOCADMIN_API_BASE)

      if (appEditing === 'true') { commit('setEditing', appEditing) }
      if (debug === 'true') { commit('setDebug', debug) }
      if (theme) { commit('setGlobalTheme', theme) }
      if (readingLayout) { commit('setReadingLayout', readingLayout) }
      if (readingLanguageOrder) { commit('setReadingLanguageOrder', readingLanguageOrder) }

      if (helperEnabled === 'true') { 
        const helperLayout = localStorage.getItem('appHelperLayout')
        const helperTrans = localStorage.getItem('appHelperTransliteration')
  
        commit('setHelperEnable', helperEnabled)
        if (helperTrans) { commit('setHelperTransliteration', helperTrans) }
        if (helperLayout) { commit('setHelperLayout', helperLayout) }
      }
    },
    setSnackBar: ({ commit }, text) => {
      commit('SET_SNACKBAR', text)
    },
  },
  mutations: {
    /**
     * Theme and Layout
     */
    setGlobalTheme: (state, theme) => {
      console.log(`setGlobalTheme: theme=${theme}`)
      Vuetify.framework.theme.dark = theme === 'dark'
      state.globalTheme = theme
      localStorage.setItem('appTheme', state.globalTheme)
    },
    setHelperEnable: (state) => {
      state.helperEnable = !state.helperEnable
      localStorage.setItem('appHelperEnabled', state.helperEnable)
    },
    setHelperTransliteration: (state, trans) => {
      state.helperTransliteration = trans
      localStorage.setItem('appHelperTransliteration', trans)
    },
    setHelperLayout: (state, layout) => {
      state.helperLayout = layout
      localStorage.setItem('appHelperLayout', layout)
    },
    setReadingLayout: (state, layout) => {
      state.readingLayout = layout
      localStorage.setItem('appReadingLayout', layout)
    },
    setReadingLanguageOrder: (state, orderArr) => {
      state.readingLanguageOrder = Array.isArray(orderArr) ? orderArr: JSON.parse(orderArr)
      localStorage.setItem('appReadingLanguageOrder', JSON.stringify(orderArr))
    },
    setRTL: (state, isRTL) => {
      Vuetify.framework.rtl = isRTL
      state.isRTL = isRTL
    },
    toggleDebug: (state) => {
      state.debug = !state.debug
      localStorage.setItem('appDebug', state.debug)
    },
    toggleEditing: (state) => {
      state.editing = !state.editing
      localStorage.setItem('appEditing', state.editing)
    },
    setDebug: (state, payload) => (state.debug = payload),
    setEditing: (state, payload) => {
      state.editing = payload
      localStorage.setItem('appEditing', payload)
    },
    toggleDrawer: (state) => (state.drawer = !state.drawer),
    setDrawer: (state, payload) => (state.drawer = payload),
    updateMenu: (state, menu) => {
      // check if document exists in menu and if its the same
      if (state.menu.some((e) => e.key === 'document') && state.menu.some((e) => e.text === menu.text)) {
        return
      }

      // remove old menu and add new
      const index = state.menu.findIndex((o) => o.key === 'document')

      if (index > -1) {
        state.menu.splice(index, 1)
      }
      
      state.menu.push(menu)

      return
    },
    log: (state, payload) => {
      
      const { source, text, silent, error } = payload
      
      state.logItems.push({
        stamp: Math.floor(Date.now() / 1000),
        source: source,
        message: text,
        error: error
      })

      if (silent && error) {
        console.error(`${source} = ${text}`)

        return
      }
      
      if (!silent) {
        let timeout = 0

        if (state.snackbar.show) {
          timeout = 300
          state.snackbar.show = false
        }
  
        setTimeout(() => {
          state.snackbar.show = true
          state.snackbar.text = text
        }, timeout)
      }

    },
    SET_SNACKBAR (state, text) {
      let timeout = 0

      if (state.snackbar.show) {
        timeout = 300
        state.snackbar.show = false
      }

      setTimeout(() => {
        state.snackbar.show = true
        state.snackbar.text = text
      }, timeout)
    },
    hideSnackBar: (state) => {
      state.snackbar.show = false
    }
  }
}