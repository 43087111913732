import DocAdmin from '@/store/data'

export default {
  namespaced: true,
  state : () => ({ 
    termTypes: undefined
  }),
  getters: {
    termTypeNameById: (state) => (termTypeId) => {
      if (state.termTypes && state.termTypes.length) {
        const item = state.termTypes.find((v) => Number(v.termtypeid) === Number(termTypeId))

        return item
      }
    }
  },
  actions: {

    /**
    * Load TermTypes
    *
    * @param {Object} context - Vuex context
    * @return {Promise<Object>}
    */
    getTermTypes({ commit }) {
      return DocAdmin.termTypes.list().then((response) => {
        commit('SET_TERMTYPES', response.data.results)
      }).catch((error) => console.error('failed to load TermTypes.'))
    }
  },
  mutations: {
    SET_TERMTYPES(state, termTypes) {
      state.termTypes = termTypes
    }
  }
}