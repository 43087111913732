import axios from 'axios'
import store from '@/store'
import router from '@/router'
import configs from '@/configs'

const docAdminConfig = configs.application.docAdmin
const baseUrl = docAdminConfig.baseUrl + '/'

const axiosBase = axios.create()

// Add a request interceptor
axiosBase.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token')
    const csrf_token = document.head.querySelector('meta[name="csrf-token"]')

    // window.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
    if (csrf_token) {
      // an interceptor would overwrite this due to right-to-left precedence
      window.axios.defaults.headers.common['XSRF-TOKEN'] = csrf_token.content
    }

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }

    // config.headers['Content-Type'] = 'application/json';
    return config
  }, (error) => {
    Promise.reject(error)
  }
)

//Add a response interceptor
axiosBase.interceptors.response.use((response) => {
  return response
}, async (error) => {
  const originalRequest = error.config
  const token = localStorage.getItem('access_token')

  if (error.response.status === 401 && (originalRequest.url.includes('account/token') || originalRequest.url.includes('token/refresh'))) {
    router.push(configs.settings.loginPath)
    
    return Promise.reject(error)
  }

  if (error.response.status === 401 && !originalRequest._retry && token) {

    originalRequest._retry = true
    await store.dispatch('account/refreshToken')
    axiosBase.defaults.headers.common['Authorization'] = `Bearer ${ token }`

    return axiosBase(originalRequest)
  }

  return Promise.reject(error)
})

export {
  axiosBase
}