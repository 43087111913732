export default {
  audio: {
    bhaktisoundBaseUrl: "https://www.swaminarayan.faith/bhaktisound",
    types: [
      { text: 'Recital', value: 'recital', icon: '' },
      { text: 'Kirtan', value: 'kirtan', icon: '' },
      { text: 'Commentary', value: 'commentary', icon: '' }
    ],
    sources: [
      { text: 'Soundcloud', value: 'soundcloud', icon: '' },
      { text: 'Bhaktisound', value: 'bhaktisound', icon: '' },
      { text: 'File', value: 'file', icon: '' },
      { text: 'Url', value: 'url', icon: '' }
    ]
  },
  imageTypes: [
    "banner",
    "thumbnail"
  ]
}