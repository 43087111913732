import DocAdmin from '@/store/data'

export function extractFacets( results, filters ) {

  const facets = []

  for ( const rawName in results.facets ) {
    if ( !rawName.startsWith('_filter_') ) {
      continue
    }
    const data = results.facets[rawName]
    const name = rawName.substring(8)
    const facet = data[name]

    facets.push( {
      name: name,
      type: facet.meta.type,
      multi: facet.meta.multi,
      param: facet.meta.param,
      missingValue: facet.meta.missing_value,
      missingParam: facet.meta.missing_param,
      docCount: data.doc_count,
      otherCount: facet.sum_other_doc_count,
      buckets: facet.buckets.sort( ( a ) =>
        // Push missing value buckets to the end of the list
        a.key === facet.meta.missing_value ? 1 : 0
      ),
      selected: filters.reduce( ( acc, val ) => {
        if (val[0] === facet.meta.param ) {
          acc.push(val[1] )
        }
        if (val[0] === facet.meta.missing_param ) {
          acc.push( facet.meta.missing_value )
        }

        return acc
      }, [])
    })
  }

  return facets
}

export default {
  namespaced: true,
  state: () => ({
    documentSearchResults: undefined,
    documentSearchQueryParams: undefined
  }),
  actions: {
    /**
     * Search for documents.
     *
     * @param {Object} commit - Vuex commit from context object
     * @param {Object} payload
     * @param {string} payload.query - user provided query
     * @param {number} payload.page - page number within results
     * @param {number} payload.pageSize - number of tools per page
     * @param {string} payload.ordering - Sort order
     * @param {string[][]} payload.filters - filters to add to query
     * @return {Promise}
     */
    findDocuments({ commit }, { payload, options }) {
      const filters = payload.filters || []
      const params = [
        ...filters,
        ['ordering', payload.ordering || null],
        ['page', payload.page || null],
        ['page_size', payload.pageSize || null]
      ]
      if (!options) {
        params.push(['q', payload.query || null])
      } else {
        if (options.search === 'default') {
          params.push(['q', payload.query || null])
        }
  
        if (options.search === 'regex') {
          params.push(['body.raw__regexp', payload.query || null])
        }
  
        if (options.search === 'wildcard') {
          params.push(['body.raw__wildcard', payload.query || null])
        }
      }

      // removing nulls
      const cleanParams = new URLSearchParams(
        params.filter((value) => {
          const val = value[1]
    
          return val !== null && val !== undefined
        })
      )

      const entries = cleanParams.entries() //returns an iterator of decoded [key,value] tuples
      const paramsO = Object.fromEntries(  
        new URLSearchParams(entries)
      )
  
      console.log(`SearchPage.findDocuments = paramsO=${JSON.stringify(paramsO)}`)  

      return DocAdmin.documentSearch.list({
        params: paramsO
      }).then((response) => {
        const results = response.data

        results.facets = extractFacets(results, filters)

        commit('SET_DOCUMENT_SEARCH_RESULTS', { results, qs: cleanParams })
      }).catch((error) => console.error('search.findDocuments.error', error))
    }
  },
  mutations: {
  /**
   * Store /api/search/documents/ results.
   *
   * @param {Object} state - Vuex state tree.
   * @param {Object} payload - mutation payload.
   * @param {Object} payload.results - API response
   * @param {Object} payload.qs - Query string params
   */
    SET_DOCUMENT_SEARCH_RESULTS( state, { results, qs } ) {
      state.documentSearchResults = results
      state.documentSearchQueryParams = qs
    }
  }
}
